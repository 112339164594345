/* progressBar.css */
.progress-bar-container {
  width: 100%;
  height: 10px;
  background-color: #ddd;
  border-radius: 5px;
  overflow: hidden;
  margin: 20px 0; /* Adjust spacing as needed */
}

.progress-bar {
  height: 100%;
  background-color: #cfe571;
  transition: width 0.2s;
}
