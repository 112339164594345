/* Reset default margins and paddings */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Shell */
  .shell {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    color: #ffffff;
    background-image: url('Assets/angryimg.png'); /* Use your background image */
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  /* Header */
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .logo {
    max-width: 60px;
    height: auto;
    margin-bottom: 10px;
  }
  
  .app-name {
    font-family: 'gilroy_bold';
    font-size: 4vh;
  }
  
  /* Main Content */
  .main-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .screenshot-placeholder {
    margin-bottom: 30px;
  }
  
  .screenshot-placeholder-box {
    width: 200px; /* Adjust as needed */
    height: 400px; /* Adjust as needed */
    background-color: rgba(255, 255, 255, 0.1);
    border: 2px dashed #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .screenshot-placeholder-box p {
    color: #ffffff;
    font-size: 16px;
  }
  
  .explanation-text {
    max-width: 600px;
  }
  
  .explanation-text p {
    font-size: 18px;
    line-height: 1.5;
  }
  
  /* Footer */
  /* .footer {
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.5);
  } */
  
  .footer-links {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .footer-links a {
    color: #ffffff;
    margin: 0 10px;
    font-size: 14px;
    text-decoration: none;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  
  .beta-request {
    text-align: center;
  }
  
  .beta-request p {
    font-size: 16px;
  }
  
  .beta-request a {
    color: #ffffff;
    text-decoration: underline;
  }
  
  /* Responsive Design */
  @media screen and (max-width: 600px) {
    .screenshot-placeholder-box {
      width: 150px;
      height: 300px;
    }
  
    .explanation-text p {
      font-size: 16px;
    }
  
    .footer-links a {
      font-size: 12px;
      margin: 0 5px;
    }
  
    .beta-request p {
      font-size: 14px;
    }
  }
  