/* Reset default margins and paddings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Shell */
.shell {
  display: flex;
  flex-direction: column;
  height: 100vh;
  color: #ffffff;
  background-image: url('Assets/angryimg.png');
  background-repeat: no-repeat;
  background-size: cover;
}

/* Header */
.header {
  flex: 0 0 15%; /* Adjust as needed */
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center;    /* Center horizontally */
  justify-content: center; /* Center vertically */
}

.logo {
  max-width: 60px;
  height: auto;
  display: block;
  margin: 0; /* Remove any default margins */
}

.app-name {
  font-family: 'gilroy_bold';
  margin-top: 10px; /* Space between logo and title */
  font-size: 4vh;
  margin: 0; /* Reset default margins */
}

/* Main Content */
.main-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'dm_sans';
}

.info {
  text-align: center;
}

.title {
  font-size: 3vh;
}

.author-name {
  font-size: 2.5vh;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.location {
  font-size: 2vh;
  color: #888;
}

/* Responsive Image */
.responsive-image {
  width: auto;
  height: 20vh;
  max-height: 200px;
  border-radius: 10px;
}

/* Footer */
.footer {
  flex: 0 0 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Audio Player */
.audio-player audio {
  width: 100%;
  max-width: 300px;
}

/* Media Queries for Smaller Screens */
@media screen and (max-width: 600px) {
  .header,
  .footer {
    flex: 0 0 12vh;
  }

  .app-name {
    font-size: 3.5vh;
  }

  .title {
    font-size: 2.5vh;
  }

  .author-name {
    font-size: 2vh;
  }

  .location {
    font-size: 1.8vh;
  }

  .responsive-image {
    height: 25vh;
  }

  .logo {
    width: 30%;
    max-width: 80px;
  }
}
